.drop-card-custom{
    position: absolute;
    left: -10px;
    top: 45px;
    z-index: 9999999;
    padding: 20px 15px ;
    display: block;
    border-radius: 5px;
    width: max-content;
}
.svg-icon-default{
    width: 20px;
    height: 20px;
}
.svg-icon-default svg{
    width: 20px;
    height: 20px;
}

.checkbox {
    opacity: 0;
    position: absolute;
  }
  
  .label {
    width: 35px;
    height: 18px;
    background-color:#111;
    display: flex;
    border-radius:50px;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    position: relative;
    transform: scale(1.5);
  }
  
  .ball {
    width: 15px;
    height: 15px;
    background-color: white;
    position: absolute;
    top: 2px;
    left: 2px;
    border-radius: 50%;
    transition: transform 0.2s linear;
  }
  
  /*  target the elemenent after the label*/
  .checkbox:checked +  .label-root .ball{
    transform: translateX(18px);
  }
  
