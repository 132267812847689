.root-modal-overlay {
  inset: 0;
  position: fixed;
  background-color: rgba(104, 104, 104, 0.795);
  z-index: 44;
}
.root-modal-container {
  position: absolute;
  inset: 50% auto auto 50%;
  /* border: 1px solid rgb(204, 204, 204); */
  /* background: rgb(255, 255, 255); */
  outline: none;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  height: max-content;
  min-width: 200px;
  z-index: 48;
}
.root-modal-container .modal-header {
}
.root-modal-container .modal-body {
  margin-top: 20px;
  margin-bottom: 20px;
}
.modal-header .fa-times {
  margin-left: 25px;
  color: rgba(31, 31, 31, 0.602);
}
.modal-header .fa-times:hover {
  color: rgba(31, 31, 31, 0.829);
  transform: scale(1.1);
  cursor: pointer;
}
.modal-header .modal-title {
  font-weight: 600;
  font-size: 1.2rem;
}
.custom-modal-footer {
}
.modal-root-cont {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3000;
}
.modal-body {
  width: 700px;
  max-width: 90vw;
}
.close-btn:after{
  display: inline-block;
  content: "\00d7"; /* This will render the 'X' */
  font-weight: bolder;
  font-size: 1.6rem;
  color: rgb(255, 113, 113);
   display: flex;
   justify-content: center;
   margin-top: -5px;
}
