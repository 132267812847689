.connectionLost_root{
    position :fixed;
    width : 100vw;
    height : 100vh;
    background-color : rgba(232, 232, 232, 0.927) ;
    left :0;
    right :0;
    bottom: 0;
    z-index: 99999999999;
}
.connectionLost_data{
    max-width:100vw;
    width : 800px;
     margin: 0 auto;
     margin-top: 50vh;
     transform: translateY(-50%);
    background-color: white;
    box-shadow: 2px 2px 5px  rgb(220, 220, 220);
    padding: 40px;
    border-radius:5px;
}
.connectionLost_left_cont{
    justify-content: center;
}
.connectionLost_left_cont .btn-primary{
    width:max-content;
}
.connectionLost_right_cont img{
   width: 290px;
   max-width: 30vw;
}
.no_connection_head{
    font-size: 2rem;
    font-weight: bold;
}
@media only screen and (max-width: 600px) {
    .mobile-flex-column-reverse{
        flex-direction: column-reverse;
    }
  }