/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.content-container {
  height: calc(100vh - 114px) !important;
  /* background-color: brown !important; */

}

/* .first-col {
    width: 100px;
    min-width: 100px;
    max-width: 100px;
    left: 0px;
    background-color: red;

  }

  .second-col {
    width: 150px;
    min-width: 150px;
    max-width: 150px;
    left: 100px;
    background-color: white;

  }
  .sticky-col {
    position: -webkit-sticky;
    position: sticky;
  }
  .wrapper {
    position: relative;
    overflow: auto;
    border: 1px solid black;
    white-space: nowrap;
  } */

  .rating-modal{
    width: 100vw !important;
    max-width: 650px !important;
  }
  .h-300{
    height: 150px !important;
  }
  *,*::before,*::after{
    box-sizing: border-box;
  }
  .mobile-menu-button-cont{
    order: 5;
  }
  .border-right {
    border-right: 1px solid rgb(102, 102, 102);
  }

  .tabs .tab{
    padding: 3px 10px;
    cursor: pointer;
  }
  .tabs .active-tab{
  background-color: rgb(226, 255, 217);
  }
  .close-modal-btn{
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 10px;
    color: red;

  }
  /* @media only screen and (max-width: 600px){
    .close-modal-btn{
      top: -5px;
    }
  } */
  .home-logo{
   min-height: 2.5rem;
   min-width: 2.5rem;
  }
  .test-name{
    white-space: nowrap;
    margin-right: 5px;
  }
  /* .test-table-skill tr{
   display: flex;
  }
  .test-table-skill td, .test-table-skill th{
  width: 180px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  justify-items: center !important;
  } */
  /* .test-table-skill .sticky {
  width: 180px;
   position: absolute;
  } */

  .Toastify{
    z-index: 999999999999999999 !important;
    position: relative;
  }
  .failed_session_btn{
    position: fixed;
    bottom: 70px;
    right: 0;
    z-index: 9 !important;
    opacity: 0.8;
    padding-left: 25px;
    padding-right: 25px;
  }
  .error_session_root{
    height:100vh !important;
    width: 450px;
    padding :2px !important;
    position: fixed !important;
    inset: unset !important;
    right: 0 !important;

    margin-right: 0 !important;
    transform: none !important;
    top: 0 !important;
    max-width: 100vw;
  }
  .error_session_root::-webkit-scrollbar{
   display: none;
  }
  .error_info_goBack{
    position: sticky;
    top: 0;
  
  }