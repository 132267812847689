.progress-round-cont{
    transform: scale(0.45);
  
  }
  .rounded-progress-cont{
      height: 80px;      
  }
  .report-each-skill{
   padding: 5px;
   align-items: center ;
   justify-content: center ;
   border-radius: 5px;
   position: relative;
  }
  .report-each-skill:not(:last-child){
   margin-right: 10px;
  }
  .report-each-skill .skillName{
  height: 50px;
  }
.student-info-cont .custom-label{
    width: 150px;
}

.view-svg-in-card{
    position: absolute;
    right: 10px;
    bottom: 10px;
    opacity: 0.6;
}
.report-of-all-skills{
    overflow-x: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.report-of-all-skills::-webkit-scrollbar{
   display: none;
}
.full-info-modal{
    position: relative;
    z-index: 9999999999;
}
.btn{
    padding: 8px 15px;
    color: white;
    border-radius: 5px;
}
.btn-primary{
    background-color: rgb(49,46,129);
}
.btn-secondary{
  color:rgb(49,46,129);
  border: 1px solid rgb(49,46,129);
  transition: all 0.4s;
}
.btn-secondary:hover{
    background-color: rgb(49,46,129);
    color: white;

}
.btn-primary:disabled{
    padding: 8px 15px;
    background-color: rgb(214, 214, 214);
    color: white;
    border-radius: 5px;
}
.full-report-table-cont .table-flex{
    display: grid;
    grid-template-columns: 3fr 100px 4fr;
    gap: 1px;
    /* background-color: rgb(248, 248, 248); */
}
.bg-primary{
    background-color: rgb(49,46,129);
}
.text-primary{
    color: rgb(49,46,129);
}
.fullinfo-body{
    max-height: 80vh;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    
}
.fullinfo-body::-webkit-scrollbar{
    display: none;
    
}

.grade-z-index{
    z-index: 7;
    position: relative;
}
.border-bottom-grey{
    border-bottom: 2px solid white;
}
.dark .border-bottom-grey{
    border-bottom: 2px solid black;
}
.flex-center{
    display: flex;
    align-items: center;
    justify-content: center;
}
.top-z-index{
    z-index: 99999999999 !important;
}
.goback-z-index{
    z-index: 999999999 !important;
}
.flex-column{
    flex-direction: column;
}
.w-100{
    width: 100%;
}
.finalize-check{
    width: 60px;
    height: 35px;
    /* background-color: rgb(0, 255, 17); */
    background-color: rgb(51,53,85);
    position: absolute;
    right: -10px;
    top: -10px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* opacity: 0.7; */

}


.finalize-check img{
   height: 20px;
}
.resize-75{
    transform: scale(0.75);
}
.line-height-0{
    line-height: 1 !important;
}
.default-svg-size{
    height: 20px;
    width: 20px;
}
.dark .default-svg-size{
   color: rgb(237, 212, 255);
}
.align-center{
    align-items: center !important;
}

.switch-rows{
    position: absolute;
    cursor: pointer;
    left: 10px;
    top: 10px;
    z-index: 500;
    background-color: #fff;
    padding: 3px;
    border-radius: 6px;
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.283);
}
.switch-rows svg{
    width: 20px;
    height: 20px;
}
.invert .invert-sticky-left{
    left: 0 !important;
    position: sticky !important;
}
.invert .revert-flex-col{
    flex-direction: row !important;
}
.revert-self-column{
    flex-basis: 5;
}
.revert-ml-2{
    margin-left: 10px;
}

.test-table-skill table{
    min-height: calc(100vh - 115px);
}