.back-drop-popup{
    position: fixed !important;
    left: 0;
    right: 0;
    background-color:rgba(0, 0, 0, 0.085);
    z-index: 100;
    bottom: 0;
    top: 0;
}
.dropdown-content{
    z-index: 101;
}
.more-drop-card-dist{
    position: absolute;
    top: 40px;
   left: 50%;
   transform: translateX(-50%)
}

.root_dropcard{
    max-height: 55vh;
}