.progress-bar-cont{
    max-width: 10px;
    width: 20px;
    height: 100%;
    min-height: 50px;
    background-color: rgb(230, 230, 230);
    border-radius: 3px;
    position: relative;
}
.progress{
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgb(35, 37, 107);
    border-radius: 3px;
    width: 100%;
}